import { useState, useEffect } from 'react';

export default function useDeviceType() {
  const [deviceType, setDeviceType] = useState('');

  useEffect(() => {
    const userAgent = navigator.userAgent;

    if (!!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i) || !!userAgent.match(/iPod/i)) {
      setDeviceType('ios');
    } else if (!!userAgent.match(/Android/i)) {
      setDeviceType('android');
    } else {
      setDeviceType('unknown');
    }
  }, []);

  return deviceType;
}