import { Location } from "@reach/router"
import React from "react"
import "../styles/layout.scss"

import Header from "../components/Header"
import Footer from "./Footer"
import styled from "styled-components"

import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const Main = styled.main`
  padding: ${props => (props.isTickethoss ? "40px 10%" : "0")};

  @media (max-width: 768px) {
    padding: 10px 0px;
  }
`

const Separator = styled.div`
  height: ${props => (props.height ? props.height : "0px")};

  @media (max-width: 768px) {
    height: 62px;
  }
`

const Layout = ({
  children,
  margin = 0,
  maxHeight = false,
  menuChosen,
  setMenuChosen,
}) => {
  const isTickethoss = process.env.GATSBY_APP_PLATFORM === "tickethoss"

  return (
    <Location>
      {({ location }) => {
        const isWaiverSigningPage =
          location.pathname === "/minor-waiver-signing/"
        const isWaiverSuccessPage = location.pathname === "/waiver-success/"
        const isNewsPage = location.pathname.includes("/news")

        const height = isWaiverSigningPage ? 0 : "106px"

        return (
          <>
            <div
              style={{
                minHeight: "68%",
                backgroundColor:
                  !isWaiverSigningPage && !isWaiverSuccessPage
                    ? "#000033"
                    : "#fff",
                height:
                  isWaiverSigningPage || isWaiverSuccessPage ? "120vh" : "",
              }}
            >
              {!isWaiverSigningPage && !isWaiverSuccessPage && (
                <Header
                  height={height}
                  menuChosen={menuChosen}
                  setMenuChosen={setMenuChosen}
                />
              )}
              <Separator height={height} />
              <Main
                style={{
                  marginTop: margin,
                  height: maxHeight ? "100%" : "",
                }}
                isTickethoss={isTickethoss || location.pathname !== "/"}
              >
                {isNewsPage ? (
                  !isTickethoss ? (
                    children
                  ) : (
                    <div
                      style={{
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "15%",
                      }}
                    >
                      Page not found
                    </div>
                  )
                ) : (
                  children
                )}
              </Main>
            </div>

            <ToastContainer />
            {!isWaiverSigningPage && !isWaiverSuccessPage && <Footer />}
          </>
        )
      }}
    </Location>
  )
}

export default Layout
