import React from "react"
import styled from "styled-components"

const HeaderMenuSelectedItem = styled("div")({
  color: "white",
  borderRadius: 8,
  border: "2px solid",
  borderColor: "rgba(250, 70, 22, 1)",
  justifyContent: "center",
  alignItems: "center",
  padding: "6px 8px",
  display: "flex",
  margin: "0 5px",
  fontSize: "1.75em",
  lineHeight: "30px",
  fontWeight: 700,
  letterSpacing: 0,
  cursor: "pointer",
  minWidth: 126,
  maxHeight: 52,
})

const HeaderMenuItem = styled("div")({
  color: "#000033",
  borderRadius: 8,
  border: "2px solid",
  backgroundColor: "white",
  borderColor: "#000033",
  justifyContent: "center",
  alignItems: "center",
  padding: "6px 8px",
  display: "flex",
  margin: "0 5px",
  fontSize: "1.75em",
  fontWeight: 700,
  letterSpacing: 0,
  cursor: "pointer",
  lineHeight: "30px",
  minWidth: 126,
  maxHeight: 44,
})

const HeaderMenuImage = styled("img")({
  width: "24px",
  height: "24px",
  "margin-right": "8px",
})

export default function HeaderMenu(props) {
  const {
    title,
    image,
    imageChosen,
    selected,
    changePage,
    minWidth,
    minHeight,
    titleFont,
    onClick,
  } = props
  return (
    <div
      style={{
        minWidth: minWidth ? minWidth : "100%",
        minHeight: minHeight ? minHeight : "100%",
      }}
      onClick={onClick}
    >
      {!selected ? (
        <HeaderMenuItem onClick={changePage}>
          <HeaderMenuImage src={image} alt={title} />
          <span
            style={{
              whiteSpace: "nowrap",
              fontSize: titleFont ? titleFont : "",
            }}
          >
            {" "}
            {title}{" "}
          </span>
        </HeaderMenuItem>
      ) : (
        <HeaderMenuSelectedItem
          style={{
            margin: minWidth ? 0 : "",
            minHeight: minHeight ? minHeight : "100%",
          }}
        >
          <HeaderMenuImage src={imageChosen} alt={title} />
          <span
            style={{
              whiteSpace: "nowrap",
              fontSize: titleFont ? titleFont : "",
            }}
          >
            {" "}
            {title}{" "}
          </span>
        </HeaderMenuSelectedItem>
      )}
    </div>
  )
}
