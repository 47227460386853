import { Link } from "gatsby"
import styled from "styled-components"

export const FooterMainContainer = styled.div`
  background-color: #000033;

  :before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      177.91deg,
      rgba(0, 0, 51, 0) 1.76%,
      #000033 10%
    );
    transform: translate3d(0px, -31px, 0) scale(0.92);
    filter: blur(48px);
    opacity: var(0.7);
    transition: opacity 0.3s;
    border-radius: inherit;
  }

  /* 
* Prevents issues when the parent creates a 
* stacking context. (For example, using the transform
* property )
*/
  ::after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    border-radius: inherit;
  }
`

export const FooterContentContainer = styled.div`
  background-color: rgba(0, 0, 51, 0.2);
  padding: 40px 7% 20px;

  @media (max-width: 768px) {
    padding: 40px 0% 60px;
  }
`

export const FooterBackground = styled.img`
  position: relative;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  object-fit: fill;
`

export const FirstRowWrapper = styled.div`
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(217, 217, 217, 0.5);
  justify-content: space-evenly;

  @media (max-width: 768px) {
    padding-bottom: 12px;
  }
`

export const SecondRowWrapper = styled.div`
  padding: 25px 0px 0px;

  @media (max-width: 768px) {
    padding-top: 0px;
  }
`

export const TickethossOrangeContainer = styled.img`
  max-width: 126px;
  max-height: 125px;
  min-width: 70px;
  min-height: 70px;
  object-fit: fill;
  width: 20%;

  @media (max-width: 768px) {
    width: 100%;
    margin-inline: auto;
    max-width: 70px;
    max-height: 70px;
  }
`

export const FooterText = styled.span`
  width: 25%;
  font-size: 2.375em;
  color: white;
  font-weight: 700;
  padding-left: 50px;
  padding-top: 40px;
  white-space: nowrap;
  min-width: 350px;

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    padding-top: 0px;
    font-size: 1.5em;
    padding-left: 10px;
    min-width: 150px;
  }
`

export const AppContainer = styled.div`
  width: 45%;
  margin-left: 7%;
  padding-top: 30px;
  justify-content: end;
  display: flex;

  @media (max-width: 768px) {
    width: 100%;
    margin-inline: auto;
    justify-content: center;
    padding-top: 0px;
  }
`

export const AppLink = styled.a`
  padding-inline: 10px;
`

export const AppImage = styled.img`
  width: 100%;
  max-width: 212px;
  min-width: 212px;
  max-height: 60px;
  min-height: 44px;
  object-fit: cover;
  border: 1px solid #ffffff;
  border-radius: 7px;

  @media (max-width: 768px) {
    max-width: 154px;
    min-width: 94px;
    max-height: 44px;
  }
`

export const TextContainer = styled.div`
  min-width: 210px;
  color: white;
  font-family: Roboto;
  letter-spacing: 0px;
  text-align: left;

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    display: none;
  }
`

export const TextMobileContainer = styled.div`
  min-width: 250px;
  font-size: 1.125em;
  color: white;
  letter-spacing: 0px;
  text-align: center;
  font-weight: 500;
  width: 100%;
  white-space: nowrap;
  line-height: 24px;

  @media (min-width: 768px) {
    display: none;
  }

  @media (max-width: 768px) {
    margin-top: 5px;
  }
`

export const LinksContainer = styled.div`
  flex-grow: 1;
  color: white;
  font-family: Roboto;
  letter-spacing: 0px;
  text-align: left;
  font-weight: 500;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0px;
    padding-top: 15px;

    > * {
      margin-top: 15px;
    }
  }
`

export const FooterLink = styled(Link)`
  color: #fa4616;
  padding-inline: 5px;
  white-space: nowrap;
  font-size: 1.125em;
  line-height: 24px;
  font-weight: 500;

  @media (max-width: 768px) {
    width: 34%;
    text-align: center;
    min-width: 145px;
    margin-top: 5px;
  }
`

export const SocialsContainer = styled.div`
  width: 20%;
  min-width: 150px;
  display: flex;
  justify-content: end;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
`

export const SocialLink = styled.a`
  min-width: 30px;
  min-height: 30px;
  margin-inline: 16px;

  @media (max-width: 768px) {
    padding-inline: 0;
  }
`
