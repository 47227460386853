import styled from "styled-components"

export const Styles = styled.div`
  display: flex;

  .header {
    position: fixed;
    background: #000033;
    width: 100%;
    padding: 22px;
    z-index: 4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 106px;
    border-bottom: 1px solid #888888;

    @media (max-width: 768px) {
      height: 62px !important;
      margin-right: 15px;
    }
  }

  .headerLogo {
    align-items: center;
    cursor: pointer;

    @media (min-width: 768px) {
      display: none;
    }
  }

  .login-button {
    position: relative;
    font-size: 22px;
    color: #fff;
    margin-right: 5px;
    margin-bottom: 3px;

    @media screen and (min-width: 940px) {
      margin-right: 8px;
    }

    &:hover {
      color: #00001f;
    }
  }

  .socials {
    /* float: right;
      margin-right: 39px; */
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .menu-item {
    background-color: "white";
    display: "flex";
    justify-content: "center";
    align-items: "center";
    padding: "6px 8px";
    margin-right: "10px";
    flex-shrink: "0";
    border-radius: "8px";
    border-width: "2px";
    border-style: "solid";
    color: "white";
  }

  .menu-item-selected {
    border-color: "rgba(250, 70, 22, 1)";
  }

  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    background: #fa4616;
    width: 0px;
    z-index: 40;
    transition: all 0.2s ease;

    @media (max-width: 768px) {
      overflow-y: scroll;
      height: 100vh;
    }

    &.active {
      width: 375px;
      padding: 55px 24px;
      height: 100vh;
      overflow: auto;

      padding-bottom: 80px;

      .links {
        display: grid;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .sidebarBody {
      max-width: 375px;
      margin-inline: auto;
    }

    .links {
      display: none;
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
      font-size: 1.2em;
      text-align: center;
      color: white;

      a {
        font-size: 1.75em;
        line-height: 32px;
        font-weight: 700;
        padding: 12px 40px;
        color: white;
        display: block;
        width: 100%;

        @media (max-width: 768px) {
          font-size: 1.25em;
        }
      }
    }
  }

  .hamburger {
    cursor: pointer;
  }

  .background {
    transition: all 0.1s ease;
    z-index: -1px;

    &.active {
      position: fixed;
      z-index: 39;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: rgba(0, 0, 51, 0.8);
    }
  }
`

export const CloseButton = styled.img`
  cursor: pointer;
  position: fixed;
  top: 45px;
  bottom: 0;
  right: 396px;
  z-index: 40;
  color: white;
  transition: all 0.2s ease;

  @media (max-width: 768px) {
    right: 0%;
    left: 16px;
    top: 20px;
  }
`

export const HelpMobile = styled.a`
  position: fixed;
  top: 10px;
  bottom: 0;
  right: 12px;
  z-index: 40;
  color: white;
  font-weight: 700;
  transition: all 0.2s ease;
  font-size: 1.5em;
  font-family: "Barlow Condensed";
  display: inline-block;
  height: 40px;

  @media (min-width: 768px) {
    display: none;
  }
`

export const HelpHeader = styled.a`
  color: white;
  font-weight: 700;
  font-size: 2.5em;
  font-family: "Barlow Condensed";
`

export const SideBarMenuLogoWrapper = styled.div`
  justify-content: center;
  display: flex;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    margin-bottom: 26px;
  }
`

export const MenuItem = styled.div`
  color: #000033;
  background-color: white;
  border-radius: 8px;
  border: 2px solid #000033;
  justify-content: space-between;
  display: flex;
  margin-bottom: 16px;
  font-size: 1.75em;
  line-height: 32px;
  font-weight: 700;
  letter-spacing: 0px;
  cursor: pointer;
  width: 100%;
  height: 64px;
  padding: 16px;
`

export const TextIconWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const LinksContainer = styled.div`
  @media (max-width: 768px) {
    padding-bottom: 32px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.24);
  }
`

export const HeaderMenuImage = styled.img`
  width: 20px;
  height: 21px;
  margin-right: 18px;
`

export const HeaderMenuArrow = styled.img`
  width: 10px;
  height: 16px;
  margin-inline: 8px;
`

export const DownloadMenuItem = styled.a`
  color: #000033;
  background-color: white;
  border-radius: 8px;
  border: 2px solid #000033;
  justify-content: center;
  padding: 16px;
  display: flex;
  margin: 10px 0px 20px;
  font-size: 1.75em;
  line-height: 32px;
  font-weight: 700;
  letter-spacing: 0px;
  cursor: pointer;
  width: 327px;
  height: 96px;

  @media (max-width: 768px) {
    margin-top: 32px;
    width: 100%;
  }
`

export const DownloadSmallMenuItem = styled.a`
  color: #000033;
  background-color: white;
  border-radius: 8px;
  border: 2px solid #000033;
  padding-top: 7px;
  display: flex;
  margin: 10px 0px;
  font-size: 1.75em;
  line-height: 32px;
  font-weight: 700;
  letter-spacing: 0px;
  cursor: pointer;
  width: 155px;
  height: 130px;

  @media (max-width: 768px) {
    margin-top: 32px;
    width: 49%;
  }
`
export const TickethossLogoSmallMain = styled.img`
  width: 82px;
  height: 62px;
  margin-left: 18px;
  vertical-align: middle;
`

export const PitpayLogoSmallMain = styled.img`
  width: 140px;
  height: 62px;
  margin-left: 18px;
  vertical-align: middle;
`

export const TickethossLogoSmall = styled.img`
  width: 82px;
  height: 62px;
  margin-left: 18px;
  vertical-align: middle;
  margin-top: 7px;
`
export const BigLogoContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`
export const BigLogoImage = styled.img`
  max-width: 186px;
  max-height: 104px;
`

export const SmallLogoImage = styled.img`
  max-width: 64px;
  max-height: 49px;

  @media (min-width: 768px) {
    display: none;
  }
`

export const HelpLink = styled.div`
  font-size: 1.5em;
  line-height: 20px;
  text-align: center;
  color: white;
  font-weight: 500;
  font-family: "Barlow Condensed";

  @media (min-width: 768px) {
    display: none;
  }
`

export const TrackHeaderWrapper = styled.div`
  height: ${props => (props.height ? props.height : "106px")};

  @media (max-width: 768px) {
    height: 62px;
  }
`
