import React, { useEffect, useState } from "react"
import TicketHossLogo from "../images/ticketHossLogo.svg"
import PitpayLogo from "../images/Pit Pay Orange .svg"
import TicketHossLogoWithoutPhone from "../images/tickethoss_logo_without_phone.svg"
import PitpayWhite from "../images/Pit Pay White.svg"
import flag from "../images/tracks.svg"
import flagWhite from "../images/tracks_white.svg"
import trophy from "../images/trophy 1.svg"
import trophyBlack from "../images/trophy_white.svg"
import calendar from "../images/calendar_today.svg"
import announcement from "../images/annoucement.svg"
import calendarWhite from "../images/calendar_today_white.svg"
import arrow from "../images/arrow_orange.svg"
import tickethossLogoSmall from "../images/tickethoss_logo_small.svg"
import pitpayLine from "../images/pitpaylogoorangeline.png"
import PitPayLogo from "../images/Pit Pay Orange .svg"
import KartpassLogo from "../images/kartpass - logo green.svg"
import Close from "../images/close_white.svg"
import { Link, navigate } from "gatsby"
import HamburgerMenu from "react-hamburger-menu"
import HeaderMenu from "./HeaderMenu"
import { useLocation } from "@reach/router"
import {
  Styles,
  CloseButton,
  SideBarMenuLogoWrapper,
  MenuItem,
  HeaderMenuImage,
  DownloadMenuItem,
  TickethossLogoSmall,
  BigLogoContainer,
  BigLogoImage,
  SmallLogoImage,
  HelpLink,
  TrackHeaderWrapper,
  LinksContainer,
  HeaderMenuArrow,
  DownloadSmallMenuItem,
  TextIconWrapper,
  TickethossLogoSmallMain,
  HelpMobile,
  PitpayLogoSmallMain,
} from "../styles/header.style"
import useDeviceType from "../utils/useDeviceType"
import { useMediaQuery } from "react-responsive"

const Header = props => {
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const toggleMenu = () => {
    if (!open) document.body.style.overflow = "hidden"
    else document.body.style.overflow = "visible"
    setOpen(!open)
  }

  const isTickethoss = process.env.GATSBY_APP_PLATFORM === "tickethoss"

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" })

  const deviceType = useDeviceType()

  const appLink =
    deviceType === "android"
      ? "https://play.google.com/store/apps/details?id=com.tickethoss.tickethoss"
      : "https://apps.apple.com/us/app/pit-pay/id1605673660?ls=1"

  const pitpayAppLink =
    deviceType === "android"
      ? "https://play.google.com/store/apps/details?id=com.pitpay.pitpay"
      : "https://apps.apple.com/us/app/pit-pay/id1489064521?ls=1"

  const kartpassAppLink =
    deviceType === "android"
      ? "https://play.google.com/store/apps/details?id=com.kartpass.kartpass"
      : "https://apps.apple.com/us/app/pit-pay/id1528031831?ls=1"

  const openPage = link => {
    document.body.style.overflow = "visible"
    navigate(link)
  }

  return (
    <Styles>
      {(!open || !isMobile) && (
        <TrackHeaderWrapper className="header" height={props.height}>
          <div className="headerLogo">
            <HamburgerMenu
              isOpen={open}
              menuClicked={toggleMenu}
              width={26}
              height={21}
              strokeWidth={3}
              rotate={0}
              color="#fff"
              borderRadius={4}
              animationDuration={0.1}
              cursor="pointer"
            />
          </div>
          <BigLogoContainer>
            <Link to="/">
              <BigLogoImage
                src={isTickethoss ? TicketHossLogo : PitpayLogo}
                alt="Tickethoss Logo"
              />
            </Link>
          </BigLogoContainer>

          <SmallLogoImage
            src={isTickethoss ? tickethossLogoSmall : PitpayLogo}
            alt="Tickethoss Logo"
          />
          <a
            href={
              isTickethoss
                ? "https://help.tickethoss.com"
                : "https://help.pitpay.com"
            }
            target="_blank"
            rel="noreferrer"
          >
            <HelpLink>Help</HelpLink>
          </a>

          <div className="socials">
            <div onClick={() => openPage(isTickethoss ? "/" : "/tracks")}>
              <HeaderMenu
                title="Tracks"
                selected={
                  location.pathname.includes("/track") ||
                  (isTickethoss && location.pathname === "/")
                }
                image={flag}
                imageChosen={flagWhite}
              />
            </div>
            <div onClick={() => openPage("/series")}>
              <HeaderMenu
                title="Series"
                selected={location.pathname.includes("/series")}
                image={trophy}
                imageChosen={trophyBlack}
              />
            </div>
            <div onClick={() => openPage("/events")}>
              <HeaderMenu
                title="Events"
                selected={
                  location.pathname.includes("/events") ||
                  location.pathname.includes("/event")
                }
                image={calendar}
                imageChosen={calendarWhite}
              />
            </div>
            <HamburgerMenu
              isOpen={open}
              menuClicked={toggleMenu}
              width={26}
              height={21}
              strokeWidth={3}
              rotate={0}
              color="#fff"
              borderRadius={4}
              animationDuration={0.1}
              style={{ position: "absolute", cursor: "pointer" }}
              cursor={"pointer"}
            />
          </div>
        </TrackHeaderWrapper>
      )}

      <div
        role="menu"
        tabIndex={0}
        className={`background ${open ? " active" : ""}`}
        onClick={toggleMenu}
        onKeyDown={event => {
          // insert your preferred method for detecting the keypress
          if (event.keycode === 13) toggleMenu()
        }}
      ></div>

      {open && (
        <div className={`sidebar ${open ? " active" : ""}`}>
          <Link to="/">
            <SideBarMenuLogoWrapper>
              <img
                src={isTickethoss ? TicketHossLogoWithoutPhone : PitpayWhite}
                alt="Tickethoss Logo"
              />
            </SideBarMenuLogoWrapper>
          </Link>
          {open ? (
            <div className="sidebarBody">
              <LinksContainer>
                <div onClick={() => openPage(isTickethoss ? "/" : "/tracks")}>
                  <MenuItem>
                    <TextIconWrapper>
                      <HeaderMenuImage src={flag} alt="Flag" />
                      <span>Tracks</span>
                    </TextIconWrapper>
                    <HeaderMenuArrow
                      src={arrow}
                      alt="Arrow"
                      style={{ marginTop: 8 }}
                    />
                  </MenuItem>
                </div>
                <div onClick={() => openPage("/series")}>
                  <MenuItem>
                    <TextIconWrapper>
                      <HeaderMenuImage src={trophy} alt="trophy" />
                      <span>Series</span>
                    </TextIconWrapper>
                    <HeaderMenuArrow
                      src={arrow}
                      alt="Arrow"
                      style={{ marginTop: 8 }}
                    />
                  </MenuItem>
                </div>
                <div onClick={() => openPage("/events")}>
                  <MenuItem>
                    <TextIconWrapper>
                      <HeaderMenuImage src={calendar} alt="calendar" />
                      <span>Events</span>
                    </TextIconWrapper>
                    <HeaderMenuArrow
                      src={arrow}
                      alt="Arrow"
                      style={{ marginTop: 8 }}
                    />
                  </MenuItem>
                </div>
                <a
                  href="https://pitpay.com/news"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div>
                    <MenuItem>
                      <TextIconWrapper>
                        <HeaderMenuImage
                          src={announcement}
                          alt="announcement"
                        />
                        <span>News</span>
                      </TextIconWrapper>
                      <HeaderMenuArrow
                        src={arrow}
                        alt="Arrow"
                        style={{ marginTop: 8 }}
                      />
                    </MenuItem>
                  </div>
                </a>
              </LinksContainer>
              {isTickethoss ? (
                <DownloadMenuItem
                  href={appLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span style={{ paddingTop: 16 }}>Download</span>
                  <TickethossLogoSmallMain
                    src={tickethossLogoSmall}
                    alt="Tickethoss Logo"
                  />
                </DownloadMenuItem>
              ) : (
                <DownloadMenuItem
                  href={pitpayAppLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span style={{ paddingTop: 16 }}>Get</span>
                  <PitpayLogoSmallMain src={pitpayLine} alt="PitPay Logo" />
                </DownloadMenuItem>
              )}

              <div
                className="Row"
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <DownloadSmallMenuItem
                  href={isTickethoss ? pitpayAppLink : appLink}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 0,
                  }}
                >
                  <span style={{ textAlign: "center" }}>
                    {isTickethoss ? "Download" : "Get"}
                  </span>
                  <TickethossLogoSmall
                    src={isTickethoss ? PitPayLogo : tickethossLogoSmall}
                    alt="Arrow"
                    style={{
                      marginInline: "auto",
                      marginBottom: 7,
                      width: 65,
                      height: 70,
                    }}
                  />
                </DownloadSmallMenuItem>
                <DownloadSmallMenuItem
                  href={kartpassAppLink}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 0,
                  }}
                >
                  <span style={{ textAlign: "center" }}>
                    {isTickethoss ? "Download" : "Get"}
                  </span>
                  <TickethossLogoSmall
                    src={KartpassLogo}
                    alt="Arrow"
                    style={{
                      marginInline: "auto",
                      width: 99,
                      height: 52,
                    }}
                  />
                </DownloadSmallMenuItem>
              </div>
            </div>
          ) : null}

          {!isMobile && (
            <div className="links">
              <a
                href={
                  isTickethoss
                    ? "https://help.tickethoss.com"
                    : "https://help.pitpay.com"
                }
                target="_blank"
                rel="noreferrer"
              >
                Help
              </a>
            </div>
          )}
          <div className="links" style={{ paddingBottom: 40 }}>
            <a
              href={
                isTickethoss
                  ? "https://dashboard.tickethoss.com"
                  : "https://dashboard.pitpay.com"
              }
              target="_blank"
              rel="noreferrer"
            >
              Dashboard
            </a>
          </div>
        </div>
      )}
      {open ? (
        <CloseButton onClick={toggleMenu} src={Close} alt="Close Icon" />
      ) : null}

      {open ? (
        <HelpMobile
          href="https://help.tickethoss.com"
          target="_blank"
          rel="noreferrer"
        >
          Help
        </HelpMobile>
      ) : null}
    </Styles>
  )
}

export default Header
